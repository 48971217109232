<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰{{ mode === "modify" ? " 수정" : "" }}하기<router-link
        to=""
        @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_28">
      <div class="top">
        <div class="box">
          <h4>리뷰할 상품 카테고리</h4>
          <p>{{ goodsInfo.data ? goodsInfo.data.category_path : "" }}</p>
        </div>
        <div class="box">
          <h4>리뷰의 종류</h4>
          <p v-if="goodsInfo.options">
            판매자 리뷰 >
            {{ goodsInfo.options.title1.replace("R", "리뷰") }}
          </p>
        </div>
        <div class="box">
          <h4>상품 사용 기간</h4>
          <p v-if="goodsInfo.options">
            {{ goodsInfo.options.title2.replace("R", "리뷰") }}
          </p>
        </div>
        <div class="box">
          <h4>상품명</h4>
          <p>{{ goodsInfo.data ? goodsInfo.data.goods_name : "" }}</p>
        </div>
      </div>
      <div class="sel_info">
        <ul class="depth_4">
          <li>
            <router-link to="" @click.native="imageOpen"
              ><img src="@/assets/images/sub/review_sel_ico_1.png" alt="" />
              <p>메인사진</p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="image"
              ><img src="@/assets/images/sub/review_sel_ico_2.png" alt="" />
              <p>사진</p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="openInput('link')"
              ><img src="@/assets/images/sub/review_sel_ico_3.png" alt="" />
              <p>링크</p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="openInput('frame')"
              ><img src="@/assets/images/sub/review_sel_ico_4.png" alt="" />
              <p>동영상링크</p></router-link
            >
          </li>
        </ul>
        <div class="link_wrap">
          <input
            type="file"
            name="filedata"
            @change="fileUpoload"
            style="display: none"
          />

          <div class="link_top" v-if="input">
            <input type="text" name="linkVal" v-model="linkVal" /><router-link
              to=""
              @click.native="setValue"
              >완료</router-link
            >
          </div>
          <!--원문링크-->
          <div class="link_bottom" v-if="input === 'org'">
            리뷰할 원문 뉴스 기사 url을 복사해 여기에 붙여넣기 한 후 옆의 [완료]
            버튼을 눌러주세요.
          </div>
          <!--링크-->
          <div class="link_bottom" v-if="input === 'link'">
            리뷰에 필요한 외부 내용 url을 복사해 여기에 붙여넣기 한 후 옆의
            [완료] 버튼을 눌러주세요. url은 작성 중인 글 바로 아래 줄에
            들어갑니다
          </div>
          <!--동영상링크-->
          <div class="link_bottom" v-if="input === 'frame'">
            리뷰에 필요한 외부 동영상 url을 복사해 여기에 붙여넣기 한 후 옆의
            [완료] 버튼을 눌러주세요. 해당 동영상은 임베디드 되어 (embedded)
            작성중인 글 바로 아래 줄에 들어갑니다.
          </div>
        </div>
      </div>
      <div class="cont_info">
        <div class="sect_title">
          <input
            type="text"
            name=""
            @input="changeTitle"
            :value="title"
            maxlength="50"
            placeholder="리뷰 제목을 써주세요."
          />
        </div>
        <div class="sect_thum">
          <table>
            <tbody>
              <tr>
                <td>
                  <div class="img" @click="imageOpen">
                    <img
                      :src="
                        mode === 'modify'
                          ? thumbnail
                          : require('@/assets/images/sub/status_ico.png')
                      "
                      alt=""
                      ref="mainImage"
                      @error="replaceImg"
                    />
                  </div>
                </td>
                <td v-if="!title">
                  위 메뉴에서 [메인사진] 버튼을 눌러 메인 사진을 등록해주세요.
                </td>
                <td v-else>
                  {{ title }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sect_con">
          <vue-editor
            v-model="content"
            use-custom-image-handler
            :editorToolbar="customToolbar"
            @image-added="handleImageAdded"
            :placeholder="`
여기에 리뷰할 내용을 써주세요.
또는 유튜브 리뷰의 경우, [동영상 링크] 버튼을 눌러 유튜브의 해당 URL을 입력해주세요.
동일 상품에 대해 소비자 리뷰는 1차는 제약 없이, 2차는 구매 이력이 있어야 가능합니다.
사진과 동영상의 경우, 저작권이 침해되지 않는 것들만 올려주세요. 저작권 침해에 대해 회사는 책임지지 않습니다.`"
          ></vue-editor>

          <!--상품(소비자)-->
          <!--<textarea placeholder="여기에 리뷰할 내용을 써주세요.&#13;&#10;또는 유튜브 리뷰의 경우, [동영상 링크] 버튼을 눌러 유튜브의 해당 URL을 입력해주세요.&#13;&#10;사진과 동영상의 경우, 저작권이 침해되지 않는 것들만 올려주세요. 저작권 침해에 대해 회사는 책임지지 않습니다."></textarea>--><!--상품(전문가)-->
        </div>
        <div class="sect_grade" v-if="mode !== 'modify'">
          <p>해당 제품 평점을 매겨주세요.</p>
          <table summary="">
            <tbody>
              <tr>
                <td>{{ goodsInfo.data ? goodsInfo.data.goods_name : "" }}</td>
                <td>
                  <!-- <router-link to="" @click.native="minus">▼</router-link
                  >&nbsp;<input
                    type="text"
                    name=""
                    maxlength="3"
                    v-model="score"
                    readonly
                  />점&nbsp;<router-link to="" @click.native="plus"
                    >▲</router-link
                  > -->
                  <select
                    name=""
                    id=""
                    style="vertical-align: middle; width: 50px; height: 25px"
                    v-model="score"
                  >
                    <template v-for="(n, idx) in 50">
                      <option :key="idx" :value="n / 10" v-if="n / 10 >= 1">
                        {{ n / 10 }}
                      </option>
                    </template>
                  </select>
                  점
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--상품-->
      </div>
      <div class="bottom_3">
        <h4>구매버튼 생성 여부</h4>
        <div class="sect_radio">
          <label
            ><input type="radio" name="" v-model="buttonYn" value="Y" />구매버튼
            생성</label
          >
          <label
            ><input type="radio" name="" v-model="buttonYn" value="N" />구매버튼
            생성 안함</label
          >
        </div>
      </div>
    </div>
    <!-- -->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="setNormalGoodsReviewRegister">{{
          mode === "modify" ? "수정" : "등록"
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      goodsNum: this.$route.params.goodsSeq || null,
      category: this.$route.params.category || null,
      category_code: this.$route.query.category_code || null,
      goods_seq: this.$route.query.goods_seq || null,
      option1: this.$route.query.option1 || null,
      option2: this.$route.query.option2 || null,
      option3: this.$route.query.option3 || null,
      seq: this.$route.query.seq || null,
      mode: this.$route.query.mode || null,
      pageName: this.$route.query.page_name || null,
      thumbnail: null,
      title: "",
      score: parseFloat(2.5),
      input: false,
      tmpInput: false,
      content: "",
      linkVal: null,
      val: null,
      buttonYn: "Y",
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        //["formula", "image", "video"],
        ["image", "video"],
        //["clean"], // remove formatting button
      ],
    };
  },
  created() {
    if (this.mode !== "modify") {
      if (!this.goodsInfo) {
        this.$toast.default("리뷰 종류와 상품 사용기간을 선택해 주세요.");
        this.$router.push(
          `/review/review_seller_goods_choice/${this.category}/${this.goodsSeq}`
        );
      }
    }
    if (this.mode === "modify") {
      this.getGoodsNomalReview();
    }
  },
  components: {
    VueEditor,
  },
  computed: {
    ...mapState("review", [
      "goodsInfo",
      "result",
      "msg",
      "mainImage",
      "imgUrl",
      "reviewSeq",
      "reviewData",
    ]),
    goodsSeq: {
      get() {
        if (this.goods_seq !== null) {
          return this.goods_seq;
        }
        if (this.goodsNum !== null) {
          return this.goodsNum;
        }
        return null;
      },
      set(value) {
        this.goodsNum = value;
      },
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    plus() {
      if (parseFloat(this.score) < 5.0) {
        this.score = parseFloat((parseFloat(this.score) + 0.1).toFixed(1));
      }
    },
    minus() {
      if (parseFloat(this.score) > 1.0) {
        this.score = parseFloat((parseFloat(this.score) - 0.1).toFixed(1));
      }
    },
    changeTitle(e) {
      this.title = e.target.value;
    },
    async fileUpoload() {
      const image = document.querySelector("input[name='filedata'").files[0];
      const allow_ext = ["jpg", "png", "jpeg", "gif"];
      if (!image) {
        this.$toast.default("메인 이미지 파일을 선택 해주세요.");
        return false;
      }
      let ext = image.name.split(".").pop().toLowerCase();
      const ext_check = allow_ext.some((item) => item === ext);
      if (!ext_check) {
        this.$toast.default("허용되지 않는 파일 입니다.");
        document.querySelector("input[name='filedata'").value = null;
        return false;
      }
      let formData = new FormData();
      formData.append("filedata", image);
      await this.$store.dispatch("review/submitMainImg", formData);
      document.querySelector("input[name='filedata'").value = null;
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.$refs["mainImage"].src = this.mainImage.thumbnail;
      this.thumbnail = this.mainImage.thumbnail;
      this.mainImg = this.mainImage.main;
    },
    imageOpen() {
      document.querySelector("input[name='filedata'").click();
    },
    openInput(type) {
      if (this.tmpInput === type) {
        this.input = false;
        this.tmpInput = false;
        return false;
      }
      this.input = type;
      this.tmpInput = this.input;
    },
    setValue() {
      if (this.linkVal) {
        if (this.input === "org") {
          this.addNewsLink();
        } else if (this.input === "link") {
          this.addLink();
        } else if (this.input === "frame") {
          this.embed();
        } else {
          this.$toast.default("지원 하지 않는 타입입니다.");
        }
      }
    },
    image() {
      document.querySelector(".ql-image").click(); //$refs 제외
    },
    embed() {
      const html = `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${this.extractVideoUrl(
        this.linkVal
      )}"></iframe><p><br /></p>`;
      this.content += html;
      this.isFrame = false;
      this.linkVal = null;
      this.input = false;
    },
    addLink() {
      const html = `<a href="${this.linkVal}" target="_blank">[ 관련내용 보기]</a>`;
      this.content += html;
      this.linkVal = null;
      this.input = false;
    },
    extractVideoUrl(url) {
      var match =
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
        ) ||
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
        );
      if (match) {
        return (
          (match[1] || "https") +
          "://www.youtube.com/embed/" +
          match[2] +
          "?showinfo=0& enablejsapi=1 "
        );
      }
      if (
        (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
      ) {
        // eslint-disable-line no-cond-assign
        return (
          (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
        );
      }
      this.linkVal = null;
      this.input = false;
      return url;
    },
    setNormalGoodsReviewRegister() {
      if (this.title.replace(/\s/i, "").length <= 0) {
        this.$toast.default("리뷰 제목을 작성해 주세요.");
        return false;
      }
      if (this.content.replace(/\s/i, "").length <= 0) {
        this.$toast.default("리뷰 내용을 입력해주세요.");
        return false;
      }

      if (parseInt(this.score) <= 0) {
        this.$toast.default("뉴스 중요도는 1점 보다 커야 합니다.");
        return false;
      }
      if (parseInt(this.score) > 5) {
        this.$toast.default("뉴스 중요도는 5점 만점 입니다..");
        this.score = 0;
        return false;
      }

      this.$confirm(
        `${this.goodsInfo.data.goods_name} 상품 리뷰를 ${
          this.mode === "modify" ? "수정" : "등록"
        } 하시겠습니까?`
      )
        .then(async () => {
          let formdata = new FormData();
          const img = this.mainImg ? this.mainImg : null;
          formdata.append("review_type", "S");
          formdata.append("title", this.title);
          formdata.append("content", this.content);
          formdata.append("main_img", img);
          formdata.append("thumbnail", this.thumbnail);
          formdata.append("main_score", this.score);
          formdata.append("option_category", this.goodsInfo.options.option);
          formdata.append("option_category1", this.goodsInfo.options.option1);
          formdata.append("option_category2", this.goodsInfo.options.option2);
          formdata.append("main_goods_name", this.goodsInfo.data.goods_name);
          formdata.append("goods_seq", this.goodsSeq);
          formdata.append("provider_seq", this.goodsInfo.data.provider_seq);
          formdata.append("category_code", this.goodsInfo.category_code);
          formdata.append("mode", this.mode); //mode 가 'modify' 이면 수정
          formdata.append("seq", this.seq); //리뷰 번호
          formdata.append("button_yn", this.buttonYn); //구매 버튼 생성여부
          await this.$store.dispatch(
            "review/setSellerGoodsReviewRegister",
            formdata
          );

          this.$toast.default(this.msg);
          if (this.result) {
            let url = "";
            if (this.pageName === "seller") {
              url = `/review/seller_main/${this.goodsInfo.data.cate_id}/${this.category_code}?option1=${this.goodsInfo.options.option}&option2=${this.goodsInfo.options.option1}&option3=${this.goodsInfo.options.option2}&seq=${this.reviewSeq}`;
            } else {
              url = `/review/goods_main/${this.goodsInfo.data.cate_id}/${this.goodsInfo.category_code}?option1=${this.goodsInfo.options.option}&option2=${this.goodsInfo.options.option1}&option3=${this.goodsInfo.options.option2}&seq=${this.reviewSeq}`;
            }

            this.$router.push(url);
          }
        })
        .catch(() => {
          return false;
        });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("filedata", file);

      await this.$store.dispatch("review/editorImgUpload", formData);
      if (this.result) {
        Editor.insertEmbed(cursorLocation, "image", this.imgUrl);
        resetUploader();
      } else {
        this.$toast.default(this.msg);
      }
      this.$store.dispatch("review/reviewStateReset");
    },
    async getGoodsNomalReview() {
      await this.$store.dispatch("review/getGoodsNomalReview", {
        seq: this.seq,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        // this.goBack();
        return false;
      }
      await this.$store.dispatch("review/getGoodsInfo", {
        goods_seq: this.reviewData.goods_seq,
      });
      if (!this.goodsInfo) {
        this.$toast.default("상품 정보를 가져오지 못 했습니다.");
        // this.goBack();
        return false;
      }
      this.$store.dispatch("review/addOptions", {
        option_category: this.reviewData.option_category1,
        option_category1: this.reviewData.option_category2,
        option_category2: this.reviewData.option_category3,
        option_title1: this.reviewData.option2_title,
        option_title2: this.reviewData.option3_title,
      });
      this.goodsSeq = this.reviewData.goods_seq;
      this.title = this.reviewData.title;
      this.content = this.reviewData.content;
      this.mainImg = this.reviewData.content;
      this.thumbnail = this.reviewData.thumbnail;
      this.score = this.reviewData.main_score;
      this.mainImg = this.reviewData.main_img;
      this.buttonYn = this.reviewData.button_yn;
    },
    goBack() {
      if (this.mode === "modify") {
        let url = "";
        if (this.pageName === "seller") {
          url = `/review/seller_main/${this.category}/${this.category_code}?option1=${this.option1}&option2=${this.option2}&option3=${this.option3}&seq=${this.seq}`;
        } else {
          url = `/review/goods_main/${this.category_code}?option1=${this.option1}&option2=${this.option2}&option3=${this.option3}&seq=${this.seq}`;
        }

        this.$router.push(url);
      } else {
        this.$router.push(
          `/review/review_seller_goods_choice/${this.category}/${this.goodsSeq}`
        );
      }
    },
  },
};
</script>

<style lang="scss">
.rn_my_box_28 {
  .cont_info {
    .sect_grade {
      table {
        tbody {
          tr {
            td:nth-child(2) {
              input {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
.ql-editor {
  font-size: 13px;
}
</style>
